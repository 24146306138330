import { timezones, usaStates, vanLines } from "./util";
var _ = require("lodash");

export const getStateOptions = () => {
  var result = [];
  _.forOwn(usaStates, function (value, key) {
    result.push({
      value: capitalize(value),
      label: capitalize(value) + " (" + key.toUpperCase() + ")",
    });
  });

  return result;
};

export const getVanlineOptions = () => {
  var result = [];
  _.forOwn(vanLines, function (value, key) {
    result.push({
      value: capitalize(value),
      label: capitalize(value),
    });
  });

  return result;
};

export const getTimezoneOptions = () => {
  var result = [];
  _.forEach(timezones, function (value, key) {
    result.push({
      label: value.text,
      value: value.id,
    });
  });
  return result;
};

export const getMoveStatusOptions = () => {
  var result = [
    {
      label: "Opened",
      value: 0,
    },
    {
      label: "Booked",
      value: 1,
    },
    {
      label: "Finished",
      value: 2,
    },
    {
      label: "Started",
      value: 3,
    },
    {
      label: "Canceled",
      value: 4,
    },
  ];
  return result;
};

function capitalize(string) {
  // return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
