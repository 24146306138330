export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("userToken");
  window.location.href = "/login";
};

export const can = (perms, user) => {
  if (!user) return false;
  for (var i in perms) {
    if (user.permissions.indexOf(perms[i]) === -1) {
      return false;
    }
  }
  return true;
};

export const isAdmin = (user) => {
  if (!user) return false;
  return user.type === 0;
};

export const addScrollListener = () => {
  var wrapper1 = document.getElementById("wrapper1");
  var wrapper2 = document.getElementById("wrapper2");
  var div1 = document.getElementById("div1");
  var table = document.getElementsByClassName("table")[0];

  div1.style.width = table.offsetWidth + "px";
  wrapper1.onscroll = function () {
    wrapper2.scrollLeft = wrapper1.scrollLeft;
  };
  wrapper2.onscroll = function () {
    wrapper1.scrollLeft = wrapper2.scrollLeft;
  };
};

export const isCreator = (creator_id, user) => {
  if (!user) return false;
  return creator_id && user.id === creator_id;
};

export const isFromSameCompany = (company_id, user) => {
  if (!user) return false;
  return company_id && user.company_id && user.company_id === company_id;
};

export const isMoversSyndicate = (user) => {
  if (!user) return false;
  return user && user.type === 4;
};

export const isBrotherwoodSysterwood = (user) => {
  if (!user) return false;
  return user && user.type === 5;
};

export const getNextYearDate = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

export const maskPhoneNumber = (str) => {
  const len = str.length;
  let rendomString = "";
  const newVal = str.replace(/[^0-9-]/g, "");
  for (let i = 0; i <= 12; i++) {
    if (i < len) {
      const nextChar = newVal.charAt(i);
      if (i === 3 || i === 6) {
        rendomString = `${rendomString}-`;
      }

      rendomString += nextChar;
    }
  }

  return rendomString;
};

export const maskCrewCode = (str) => {
  const len = str.length;
  let rendomString = "";
  const newVal = str.toUpperCase().replace(/[^A-Z-]/g, "");
  for (let i = 0; i <= 14; i++) {
    if (i < len) {
      const nextChar = newVal.charAt(i);
      if (i % 3 === 0 && i !== 0) {
        rendomString = `${rendomString}-`;
      }

      rendomString += nextChar;
    }
  }

  return rendomString;
};
