import React, { Component } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { getOfficeUserProfile, updateOfficeUser,getHidrentCardDetails } from "../../api-routes";
import {
  getStateOptions,
  maskPhoneNumber,
  maskCrewCode,
  getVanlineOptions,
} from "../../services";
import {Elements} from "@stripe/react-stripe-js";
import PaymentPage from "./PaymentPage";
import { loadStripe } from "@stripe/stripe-js";
import "./modals.scss";
const stripePromise = loadStripe("pk_live_BQkFMEQLQ9fiEpHFdUX2cwG5");

var _ = require("lodash");

export class EditOfficeUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {},
      profileErrors: {},
      stateOptions: getStateOptions(),
      vanLineOptions: getVanlineOptions(),
      CardDetails:{},
      showPaymentMethod:false,
      paymentModal:false,
    };
  }

  componentDidMount() {
    const _this = this;
    if (this.props.selectedUser.id) {
      if(this.props.user.type===10 || this.props.user.type===3){
        getHidrentCardDetails(this.props.user.stripe_id).then((data)=>{
          if (data.status === 200) {
            _this.setState({CardDetails:data.data.data});
            _this.setState({showPaymentMethod:true});
          } else if (data.status === 400) {
            console.log("User Payment Method not found")
          }
        })
      }
      this.setState({ profile: this.props.selectedUser });
      this.getUserProfile(this.props.selectedUser.id);
    } else {
      this.setState({
        profile: {
          name: "",
          email: "",
          fullname: "",
          password: "",
          "confirm-password": "",
          phone: "",
          vanline_affiliation: null,
          state: "",
          city: "",
          company_code: "",
          experience: "",
          is_active: false,
        },
      });
    }
  }

  getUserProfile(id) {
    const _this = this;

    getOfficeUserProfile(id).then((res) => {
      console.log("getOfficeUserProfile", res);
      if (res.status === 200) {
        const profile = res.data;
        delete profile.avatar;
        if (profile.company[0]) {
          profile.company = {
            c_id: profile.company[0].id,
            name: profile.company[0].name,
            consortium: profile.company[0].consortium !== null ? profile.company[0].consortium : false,
          };
        }
        profile.is_active =
          profile.is_active === true || profile.is_active === 1 ? true : false;
        _this.setState({ profile });
      }
    });
  }

  createPhoneNumberMask(val) {
    const str = maskPhoneNumber(val.replace(/-/g, ""));
    this.handleChange("phone", str);
  }

  createCompanyCodeMask(val) {
    const str = maskCrewCode(val.replace(/-/g, ""));
    this.handleChange("company_code", str);
  }

  handleChange(key, value, newKey = null) {
    const profile = { ...this.state.profile };

    profile[key] = value;
    if (newKey === "company_name") {
      profile.company.name = value;
    }
    if (newKey === "consortium") {
      profile.company.consortium = value;
    }
    this.setState({ profile });
  }

  submit() {
    const officeUser = { ...this.state.profile };
    const _this = this;
    this.setState({ profileErrors: {} });

    if (officeUser.id) {
      updateOfficeUser(officeUser).then((res) => {
        if (res.status === 200) {
          _this.props.profileUpdated();
        } else if (res.status === 400) {
          _this.setState({ profileErrors: res.data.errors });
        }
      });
    }
  }

  handleEditPayment=()=>{
    this.setState({paymentModal:true})
  }

  render() {
    const { isEditing } = this.props;
    const { profile, profileErrors, stateOptions, vanLineOptions,CardDetails,paymentModal } = this.state;
    console.log(CardDetails)
    return (
      <>
        {paymentModal?(<><Elements stripe={stripePromise}>
            <PaymentPage updatePayment={true} cancelButton={this.props.cancel} stripeId={this.props.user.stripe_id}/>
          </Elements></>):(<><Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Account Info" : "Add Account Info"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form className="forms-sample">
            <Form.Group className="row mb-1">
              <label htmlFor="name" className="col-sm-6 col-form-label">
                Username
              </label>
              <div className="col-sm-6">
                <Form.Control
                  type="text"
                  className="form-control"
                  value={profile.name || ""}
                  onChange={(e) => this.handleChange("name", e.target.value)}
                  id="name"
                  placeholder="Enter Username"
                />
                {profileErrors.name && (
                  <p className="text-right tx-error mt-1">
                    {profileErrors.name}
                  </p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="row mb-1">
              <label htmlFor="password" className="col-sm-6 col-form-label">
                Password
              </label>
              <div className="col-sm-6">
                <Form.Control
                  type="password"
                  className="form-control"
                  value={profile.password || ""}
                  onChange={(e) =>
                    this.handleChange("password", e.target.value)
                  }
                  id="password"
                  placeholder="Password"
                />
                {profileErrors.password && (
                  <p className="text-right tx-error mt-1">
                    {profileErrors.password}
                  </p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="row mb-1">
              <label
                htmlFor="confirm_password"
                className="col-sm-6 col-form-label"
              >
                Confirm Password
              </label>
              <div className="col-sm-6">
                <Form.Control
                  type="password"
                  className="form-control"
                  value={profile["confirm-password"] || ""}
                  onChange={(e) =>
                    this.handleChange("confirm-password", e.target.value)
                  }
                  id="confirm_password"
                  placeholder="Confirm Password"
                />
                {profileErrors["confirm-password"] && (
                  <p className="text-right tx-error mt-1">
                    {profileErrors["confirm-password"]}
                  </p>
                )}
              </div>
            </Form.Group>

            {profile.company && (
              <Form.Group className="row mb-1">
                <label
                  htmlFor="company_name"
                  className="col-sm-6 col-form-label"
                >
                  Moving Company
                </label>
                <div className="col-sm-6">
                  <Form.Control
                    type="text"
                    className="form-control"
                    value={profile.company.name || ""}
                    onChange={(e) =>
                      this.handleChange(
                        "company_name",
                        e.target.value,
                        "company_name"
                      )
                    }
                    id="company_name"
                    placeholder="Enter Company Name"
                  />
                </div>
              </Form.Group>
            )}

            <Form.Group className="row mb-1">
              <label
                htmlFor="vanline_affiliation"
                className="col-sm-6 col-form-label"
              >
                Van Line Affiliation
              </label>
              <div className="col-sm-6">
                <Typeahead
                  labelKey="label"
                  id="vanline_affiliation"
                  selected={[
                    profile.vanline_affiliation
                      ? _.find(vanLineOptions, {
                        value: profile.vanline_affiliation,
                      }) ? _.find(vanLineOptions, {
                        value: profile.vanline_affiliation,
                      }).label : ""
                      : "",
                  ]}
                  options={vanLineOptions}
                  onChange={(arr) => {
                    if (arr[0])
                      this.handleChange("vanline_affiliation", arr[0].value);
                  }}
                  placeholder="Choose a Van Line..."
                />
                {profileErrors.vanline_affiliation && (
                  <p className="text-right tx-error mt-1">
                    {profileErrors.vanline_affiliation}
                  </p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="row mb-1">
              <label htmlFor="fullname" className="col-sm-6 col-form-label">
                First and Last name
              </label>
              <div className="col-sm-6">
                <Form.Control
                  type="text"
                  className="form-control"
                  value={profile.fullname || ""}
                  onChange={(e) =>
                    this.handleChange("fullname", e.target.value)
                  }
                  id="fullname"
                  placeholder="First and Last name"
                />
                {profileErrors.fullname && (
                  <p className="text-right tx-error mt-1">
                    {profileErrors.fullname}
                  </p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="row mb-1">
              <label htmlFor="phone" className="col-sm-6 col-form-label">
                Phone
              </label>
              <div className="col-sm-6">
                <Form.Control
                  type="text"
                  className="form-control"
                  maxLength={12}
                  value={profile.phone || ""}
                  onChange={(e) => this.createPhoneNumberMask(e.target.value)}
                  id="phone"
                  placeholder="Phone"
                />
                {profileErrors.phone && (
                  <p className="text-right tx-error mt-1">
                    {profileErrors.phone}
                  </p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="row mb-1">
              <label htmlFor="state" className="col-sm-6 col-form-label">
                State
              </label>
              <div className="col-sm-6">
                <Typeahead
                  labelKey="label"
                  id="state"
                  selected={[
                    profile.state
                      ? _.find(stateOptions, { value: profile.state })
                        ? _.find(stateOptions, { value: profile.state }).label
                        : profile.state
                      : "",
                  ]}
                  options={stateOptions}
                  onChange={(arr) => {
                    if (arr[0]) this.handleChange("state", arr[0].value);
                  }}
                  placeholder="Choose a State..."
                />
                {profileErrors.state && (
                  <p className="text-right tx-error mt-1">
                    {profileErrors.state}
                  </p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="row mb-1">
              <label htmlFor="city" className="col-sm-6 col-form-label">
                City
              </label>
              <div className="col-sm-6">
                <Form.Control
                  type="text"
                  className="form-control"
                  value={profile.city || ""}
                  onChange={(e) => this.handleChange("city", e.target.value)}
                  id="city"
                  placeholder="City"
                />
                {profileErrors.city && (
                  <p className="text-right tx-error mt-1">
                    {profileErrors.city}
                  </p>
                )}
              </div>
            </Form.Group>

            <Form.Group className="row mb-1 justify-content-end mr-1">
              <div className="form-check mb-0">
                <label className="form-check-label mb-0">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={profile.company && profile.company.consortium ? true : false}
                    onChange={(e) => {
                      this.handleChange("consortium", e.target.checked, "consortium");
                    }}
                  />
                  <i className="input-helper"></i>
                  Participate in the Consortium
                </label>
              </div>
            </Form.Group>
          </form>
          {(this.props.user.type===10 || this.props.user.type===3) && this.state.showPaymentMethod && <div className="p-3 bg-white mt-2">
            <div className="d-flex justify-content-between pl-2">
              <div>
                <h4>Payment Method</h4>
              </div>
              <div>
              <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true" onClick={()=>this.handleEditPayment()}></i>
                {/* <i class='fa fa-trash-o fa-lg text-danger'></i> */}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
              <div><div><img className="hidrentSmallLogo" src ={require("../../assets/images/hidrent-small-icon.png")} alt= "hidrent logo text"/></div>
                </div>
                <div><div>**** **** **** {CardDetails?.last4}</div> <div>{CardDetails?.name}</div></div>
               
              </div>
              <div>
                <div>Expiration Date</div>
                <div>{CardDetails?.exp_month}/{CardDetails?.exp_year}</div>
              </div>
            </div>
          </div>}
        </Modal.Body>

        <Modal.Footer className="flex-wrap">
          <Button variant="success m-2" onClick={() => this.submit()}>
            Save
          </Button>
          <Button variant="light m-2" onClick={() => this.props.cancel()}>
            Cancel
          </Button>
        </Modal.Footer></>)}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditOfficeUser);
