import axios from "axios";
import interceptor from "./interceptor";

// const baseUrl = "";
const baseUrl = "";

// const baseUrl = "https://admin.labornetapp.com";
// const baseUrl = "http://35.156.154.144";

export const login = (data) => {
  return interceptor.post(`${baseUrl}/login`, data);
};

export const register = (data, formDate) => {
  return interceptor.post(`${baseUrl}/register-new?user=${data}`, formDate);
};

export const registerSite = () => {
  return interceptor.get(`${baseUrl}/registration`);
};

export const recoverPassword = (data) => {
  return interceptor.post(`${baseUrl}/recover-password`, data);
};

export const changePassword = (data) => {
  return interceptor.post(`${baseUrl}/change-password`, data);
};

export const getAllMoves = (
  offset = 0,
  search = "",
  limit = 50,
  status = null,
  isHidrent,
) => {
  return interceptor.get(
    `${baseUrl}/moves?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&query[isHidrent]=${isHidrent}&format=partial${
      status !== null ? "&query[status][]=" + status : ""
    }`
  );
};

export const createMove = (move) => {
  return interceptor.post(`${baseUrl}/moves`, move);
};

export const updateMove = (move, moveId) => {
  return interceptor.put(`${baseUrl}/moves/${moveId}`, move);
};

export const deleteMove = (moveId) => {
  return interceptor.delete(`${baseUrl}/moves/${moveId}`);
};

export const getAllApplicants = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/applicants?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const deleteApplicant = (applicantId) => {
  return interceptor.delete(`${baseUrl}/applicants/${applicantId}`);
};

export const createApplicant = (applicant) => {
  return interceptor.post(`${baseUrl}/applicants`, applicant);
};

export const createHidrentApplicant = (applicant)=>{
  return interceptor.post(`${baseUrl}/hidrent/applicants`,applicant);
}

export const hidrentPayment = (applicant)=>{
  return interceptor.post(`${baseUrl}/hidrent/payment`,applicant);
}

export const getHidrentCardDetails = (id)=>{
  return interceptor.get(`${baseUrl}/hidrent/card/details/${id}`);
}

export const updatePaymentCardDetails = (applicant) =>{
  return interceptor.put(`${baseUrl}/hidrent/payment-card/update`,applicant);
}

export const updateApplicant = (applicant, applicantId) => {
  return interceptor.put(`${baseUrl}/applicants/${applicantId}`, applicant);
};

export const getApplicant = (id) => {
  return interceptor.get(`${baseUrl}/applicants/${id}`);
};

export const getAllLaborers = (
  offset = 0,
  search = "",
  limit = 50,
  location = null,
  militaryAccess = false,
  backgroundCheck = false
) => {
  let queryString = `query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`;
  if (location) {
    queryString += `&query[lat]=${location.lat}&query[lng]=${location.lng}&query[distance]=${location.distance}`;
  }

  if (militaryAccess) {
    queryString += `&query[military]=true`;
  }
  if (backgroundCheck) {
    queryString += `&query[background_check]=true`;
  }

  return interceptor.get(`${baseUrl}/laborers?${queryString}`);
};

export const getAllDrivers = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/drivers?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getMovesList = (query = "") => {
  return interceptor.get(`${baseUrl}/moves?query=${query}&format=lookup`);
};

export const getConsortiumMembers = (id) => {
  return interceptor.get(`${baseUrl}/consortium-companies/${id}`);
};

export const getJobUsersList = (query = "") => {
  return interceptor.get(`${baseUrl}/job-users?query=${query}&format=lookup`);
};

export const getDriversList = (query = "") => {
  return interceptor.get(`${baseUrl}/drivers?query=${query}&format=partial`);
};

export const getLaborersList = (query = "") => {
  return interceptor.get(`${baseUrl}/laborers?query=${query}&format=lookup`);
};

export const getUsersList = (query = "") => {
  return interceptor.get(`${baseUrl}/users?query=${query}&format=lookup`);
};

export const getAllOfficeUsers = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/company-managers?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const doDissemble = (data) => {
  return interceptor.post(`${baseUrl}/dissemble`, data);
};

export const doSincerity = () => {
  return interceptor.get(`${baseUrl}/sincerity`);
};

export const sync = () => {
  return interceptor.get(`${baseUrl}/sync`);
};

export const callLogout = () => {
  return interceptor.get(`${baseUrl}/logout`);
};

export const callLogout2 = () => {
  return interceptor.get(`${baseUrl}/logout2`);
};

export const getAllFavourites = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/favorites?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getAllCrewLeaders = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/leaders?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getAllConsortiums = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/consortium-companies?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getAllCrewMembers = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/crew-members?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const deleteCrewMember = (id) => {
  return interceptor.delete(`${baseUrl}/crew-members/${id}`);
};

export const getAllCompanies = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/companies?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getAllCompanyCodes = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/company-codes?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getAllThreads = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/threads?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getAllPushNotifications = (
  offset = 0,
  search = "",
  limit = 50
) => {
  return interceptor.get(
    `${baseUrl}/push-notifications?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&query[show_archived]=true&format=partial`
  );
};

export const getAllLaborersBgCheck = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/laborers-backcheck?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getAllLeadersBgCheck = (offset = 0, search = "", limit = 50) => {
  return interceptor.get(
    `${baseUrl}/leaders-backcheck?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getAllCrewMembersBgCheck = (
  offset = 0,
  search = "",
  limit = 50
) => {
  return interceptor.get(
    `${baseUrl}/crew-members-backcheck?query[offset]=${offset}&query[limit]=${limit}&query[search]=${search}&format=partial`
  );
};

export const getCordinates = (address) => {
  const instance = axios.create({ withCredentials: false });
  return instance.get(
    `https://maps.google.com/maps/api/geocode/json?key=AIzaSyAz-FdWEbtc8kQydlfKiUI4XPW2G2D-Pi0&sensor=false&address=${address}`
  );
};

export const getLaborerProfile = (id) => {
  return interceptor.get(`${baseUrl}/laborers/${id}`);
};

export const createLaborer = (laborer) => {
  return interceptor.post(`${baseUrl}/laborers`, laborer);
};

export const updateLaborer = (laborer) => {
  return interceptor.put(`${baseUrl}/laborers/${laborer.id}`, laborer);
};

export const getCrewLeaderProfile = (id) => {
  return interceptor.get(`${baseUrl}/leaders/${id}`);
};

export const deleteLeader = (id) => {
  return interceptor.delete(`${baseUrl}/leaders/${id}`);
};

export const createLeader = (leader) => {
  return interceptor.post(`${baseUrl}/leaders`, leader);
};

export const updateLeader = (leader) => {
  return interceptor.put(`${baseUrl}/leaders/${leader.id}`, leader);
};

export const getCrewMemberProfile = (id) => {
  return interceptor.get(`${baseUrl}/crew-members/${id}`);
};

export const createCrewMember = (crewMember) => {
  return interceptor.post(`${baseUrl}/crew-members`, crewMember);
};

export const updateCrewMember = (crewMember) => {
  return interceptor.put(
    `${baseUrl}/crew-members/${crewMember.id}`,
    crewMember
  );
};

export const deleteCrewMemberProfile = (id) => {
  return interceptor.delete(`${baseUrl}/crew-members/${id}`);
};

export const deleteLaborer = (id) => {
  return interceptor.delete(`${baseUrl}/laborers/${id}`);
};

export const getDriverProfile = (id) => {
  return interceptor.get(`${baseUrl}/drivers/${id}`);
};

export const createDriver = (driver) => {
  return interceptor.post(`${baseUrl}/drivers`, driver);
};

export const updateDriver = (driver) => {
  return interceptor.put(`${baseUrl}/drivers/${driver.id}`, driver);
};

export const deleteDriver = (driverId) => {
  return interceptor.delete(`${baseUrl}/drivers/${driverId}`);
};

export const getOfficeUserProfile = (id) => {
  return interceptor.get(`${baseUrl}/company-managers/${id}`);
};

export const createOfficeUser = (officeUser) => {
  return interceptor.post(`${baseUrl}/company-managers`, officeUser);
};

export const updateOfficeUser = (officeUser) => {
  return interceptor.put(
    `${baseUrl}/company-managers/${officeUser.id}`,
    officeUser
  );
};

export const deleteOfficeUserProfile = (id) => {
  return interceptor.delete(`${baseUrl}/company-managers/${id}`);
};

export const createFavourite = (favourite) => {
  return interceptor.post(`${baseUrl}/favorites`, favourite);
};

export const updateFavourite = (favourite) => {
  return interceptor.put(`${baseUrl}/favorites/${favourite.id}`, favourite);
};

export const deleteFavourite = (favouriteId) => {
  return interceptor.delete(`${baseUrl}/favorites/${favouriteId}`);
};

export const getCompanyById = (id) => {
  return interceptor.get(`${baseUrl}/companies/${id}`);
};

export const createCompany = (company) => {
  return interceptor.post(`${baseUrl}/companies`, company);
};

export const updateCompany = (company) => {
  return interceptor.put(`${baseUrl}/companies/${company.id}`, company);
};

export const deleteCompany = (companyId) => {
  return interceptor.delete(`${baseUrl}/companies/${companyId}`);
};

export const getCompaniesList = (query) => {
  return interceptor.get(`${baseUrl}/companies?query=${query}&format=lookup`);
};

export const createCompanyCode = (companyCode) => {
  return interceptor.post(`${baseUrl}/company-codes`, companyCode);
};

export const deleteCompanyCode = (companyCodeId) => {
  return interceptor.delete(`${baseUrl}/company-codes/${companyCodeId}`);
};

export const createNewThread = (thread) => {
  return interceptor.post(`${baseUrl}/threads`, thread);
};

export const getThreadDetails = (id) => {
  return interceptor.get(`${baseUrl}/threads/${id}`);
};

export const deletePost = (postId) => {
  return interceptor.delete(`${baseUrl}/posts/${postId}`);
};

export const createPost = (post) => {
  return interceptor.post(`${baseUrl}/posts`, post);
};
