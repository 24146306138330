import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { can, isAdmin } from "../../services";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/moves", state: "movesPagesMenuOpen" },
      { path: "/users", state: "usersPagesMenuOpen" },
      { path: "/companies", state: "companiesPagesMenuOpen" },
      { path: "/background-check", state: "bgCheckPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  render() {
    const { user } = this.props;

    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="index.html">
            <img
              src={require("../../assets/images/LN-CONSOTRIUM.png")}
              alt="logo"
            />
          </a>
          <a className="sidebar-brand brand-logo-mini pt-3" href="index.html">
            <img src={require("../../assets/images/ico.png")} alt="logo" />
          </a>
        </div>
        <ul className="nav">
          <li className="nav-item nav-profile not-navigation-link">
            <div className="nav-link">
              <Dropdown>
                <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                  <div className="d-flex justify-content-between align-items-start">
                    {/* {user && !user.previous_id && (
                      <div className="profile-image">
                        <img
                          src={require("../../assets/images/faces/face8.jpg")}
                          alt="profile"
                        />
                      </div>
                    )} */}
                    {/* <div
                      className={`text-left add-width`}
                    >
                      <img src={require("../../assets/images/hidrent-small-icon.png")} alt="Hidrent logo"/>
                      <p className="profile-name">
                        <Trans>{user && user.fullname}</Trans>
                      </p>
                      <small className="designation text-muted text-small">
                        <Trans>{user && user.name}</Trans>
                      </small>
                      <span className="status-indicator online"></span>
                    </div> */}
                    {user?.name==="Hidrent Manager"?<div className={`text-center hidrent-logo-img`}>
                      <img src={require("../../assets/images/hidrent-small-icon.png")} alt="Hidrent logo" />
                      <p className="profile-name">Hidrent Manager</p>
                    </div>: <div
                      className={`text-left add-width`}
                    >
                      <p className="profile-name">
                        <Trans>{user && user.fullname}</Trans>
                      </p>
                      <small className="designation text-muted text-small">
                        <Trans>{user && user.name}</Trans>
                      </small>
                      <span className="status-indicator online"></span>
                    </div>}
                  </div>
                </Dropdown.Toggle>
                {/* <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="d-flex">
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                        <i className="mdi mdi-account-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-alarm-check mr-0"></i>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    <Trans>Manage Accounts</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    <Trans>Change Password</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    <Trans>Check Inbox</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={evt =>evt.preventDefault()}>
                    <Trans>Sign Out</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>*/}
              </Dropdown>
              {/* <button className="btn btn-success btn-block"><Trans>New Project</Trans> <i className="mdi mdi-plus"></i>
              </button>  */}
            </div>
          </li>
         {user?.name==="Hidrent Manager"?(can(["view moves"], user) || can(["view applicants"], user)) && (
            <li
            className={
              this.isPathActive("/moves/all-moves") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/moves/all-moves">
            <i className="mdi mdi-truck menu-icon"></i>
              <span className="menu-title">
                <Trans>Moves</Trans>
              </span>
            </Link>
          </li>
          ): (can(["view moves"], user) || can(["view applicants"], user)) && (
            <li
              className={
                this.isPathActive("/moves") ? "nav-item active" : "nav-item"
              }
            >
              <div
                className={
                  this.state.movesPagesMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("movesPagesMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-truck menu-icon"></i>
                <span className="menu-title">
                  <Trans>Moves</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.movesPagesMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/moves/all-moves")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/moves/all-moves"
                    >
                      <Trans>All Moves</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/moves/applicants")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/moves/applicants"
                    >
                      <Trans>Applicants</Trans>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}

          {(can(["view laborers"], user) ||
            can(["view drivers"], user) ||
            can(["view favorites"], user) ||
            can(["view company-managers"], user) ||
            can(["view crew-members"], user) ||
            can(["view leaders"], user)) && (
              <li
                className={
                  this.isPathActive("/users") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.usersPagesMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("usersPagesMenuOpen")}
                  data-toggle="collapse"
                >
                  <i className="mdi mdi-account menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Users</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.usersPagesMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    {can(["view laborers"], user) && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/users/laborers")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/users/laborers"
                        >
                          <Trans>Laborers</Trans>
                        </Link>
                      </li>
                    )}
                    {can(["view drivers"], user) && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/users/drivers")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/users/drivers"
                        >
                          <Trans>Drivers</Trans>
                        </Link>
                      </li>
                    )}
                    {can(["view company-managers"], user) && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/users/office-users")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/users/office-users"
                        >
                          <Trans>Office Users</Trans>
                        </Link>
                      </li>
                    )}
                    {can(["view favorites"], user) && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/users/favourites")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/users/favourites"
                        >
                          <Trans>Favorites</Trans>
                        </Link>
                      </li>
                    )}
                    {isAdmin(user) && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/users/crew-leaders")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/users/crew-leaders"
                        >
                          <Trans>Crew Leaders</Trans>
                        </Link>
                      </li>
                    )}
                    {isAdmin(user) && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/users/crew-members")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/users/crew-members"
                        >
                          <Trans>Crew Members</Trans>
                        </Link>
                      </li>
                    )}
                  </ul>
                </Collapse>
              </li>
            )}
          {(can(["view companies"], user) ||
            can(["view company-codes"], user)) && (
              <li
                className={
                  this.isPathActive("/companies") ? "nav-item active" : "nav-item"
                }
              >
                <div
                  className={
                    this.state.companiesPagesMenuOpen
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("companiesPagesMenuOpen")}
                  data-toggle="collapse"
                >
                  <i className="mdi mdi-archive menu-icon"></i>
                  <span className="menu-title">
                    <Trans>Companies</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                </div>
                <Collapse in={this.state.companiesPagesMenuOpen}>
                  <ul className="nav flex-column sub-menu">
                    {can(["view companies"], user) && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/companies/all-companies")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/companies/all-companies"
                        >
                          <Trans>All Companies</Trans>
                        </Link>
                      </li>
                    )}
                    {can(["view company-codes"], user) && (
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/companies/company-codes")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/companies/company-codes"
                        >
                          <Trans>Company Codes</Trans>
                        </Link>
                      </li>
                    )}
                  </ul>
                </Collapse>
              </li>
            )}
          {can(["view threads"], user) && (
            <li
              className={
                this.isPathActive("/messaging") ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/messaging">
                <i className="mdi mdi-message menu-icon"></i>
                <span className="menu-title">
                  <Trans>Messaging</Trans>
                </span>
              </Link>
            </li>
          )}
          {can(["view push-notifications"], user) && (
            <li
              className={
                this.isPathActive("/notifications")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/notifications">
                <i className="mdi mdi-bell-outline menu-icon"></i>
                <span className="menu-title">
                  <Trans>Notifications</Trans>
                </span>
              </Link>
            </li>
          )}
          {isAdmin(user) && (
            <li
              className={
                this.isPathActive("/background-check")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <div
                className={
                  this.state.bgCheckPagesMenuOpen
                    ? "nav-link menu-expanded"
                    : "nav-link"
                }
                onClick={() => this.toggleMenuState("bgCheckPagesMenuOpen")}
                data-toggle="collapse"
              >
                <i className="mdi mdi-account-check menu-icon"></i>
                <span className="menu-title">
                  <Trans>CID Info</Trans>
                </span>
                <i className="menu-arrow"></i>
              </div>
              <Collapse in={this.state.bgCheckPagesMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {can(["view laborers"], user) && (
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/background-check/laborers")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/background-check/laborers"
                      >
                        <Trans>Laborers</Trans>
                      </Link>
                    </li>
                  )}
                  {can(["view leaders"], user) && (
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/background-check/crew-leaders")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/background-check/crew-leaders"
                      >
                        <Trans>Crew Leaders</Trans>
                      </Link>
                    </li>
                  )}
                  {can(["view crew-members"], user) && (
                    <li className="nav-item">
                      {" "}
                      <Link
                        className={
                          this.isPathActive("/background-check/crew-members")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to="/background-check/crew-members"
                      >
                        <Trans>Crew Members</Trans>
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {isAdmin(user) && (
            <li
              className={
                this.isPathActive("/consortiums")
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/consortiums">
                <i className="mdi mdi-account-multiple menu-icon"></i>
                <span className="menu-title">
                  <Trans>Consortium</Trans>
                </span>
              </Link>
            </li>
          )}
          {/* <li className="nav-item">
            <a className="nav-link" href="http://www.bootstrapdash.com/demo/star-admin-pro-react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <i className="mdi mdi-file-outline menu-icon"></i>
              <span className="menu-title"><Trans>Documentation</Trans></span>
            </a>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}
const mapStateToProps = (state) => ({
  user: state.session.user,
  isAdmin: state.session.isAdmin,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
