import {
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { Form, Button, Modal } from "react-bootstrap";
import React, { useState } from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { hidrentPayment, updatePaymentCardDetails } from "../../api-routes";
import ModalSpinner from "../shared/ModalSpinner";
import { confirmAlert } from "react-confirm-alert";



const PaymentPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cardError, setCardError] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = (stripe, elements) => async () => {
    setIsLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setIsLoading(false);
      setCardError("Please Enter Valid Card Details");
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      const response = await hidrentPayment({ token: paymentMethod.id });
      if (response.status === 200) {
        props.handleUpdate(response);
        props.handleSub();
        console.log(response, "response")
        var userData = JSON.parse(localStorage.getItem("user"));
        userData.stripe_id = response.data.data.stripe_id;
        localStorage.setItem("user", JSON.stringify(userData));
      }
      if (response.status === 400) {
        setIsLoading(false);
        try {
          setCardError(response.data.errors.code);
        } catch (error) {
          setCardError("Please Enter Valid Card Details");
        }
      }
    }
  };

  const handleUpdate = (stripe, elements) => async () => {
    setIsLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (error) {
      setIsLoading(false);
      setCardError("Please Enter Valid Card Details");
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      const response = await updatePaymentCardDetails({ token: paymentMethod.id, stripe_id: props.stripeId });
      if (response.status === 200) {
        props.cancelButton();
        confirmAlert({
          title: "Success",
          message: "Your Payment Information update successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                props.cancelButton()
              },
            },
          ],
        });
      }
      if (response.status === 400) {
        setIsLoading(false);
        try {
          setCardError(response.data.errors.code);
        } catch (error) {
          setCardError("Please Enter Valid Card Details");
        }
      }
    }
  };

  return (<>
    {isLoading && <ModalSpinner />}
    <Modal.Header closeButton className="hidrentModalCloseButton">
      <Modal.Title>{"Payment Information"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {props.updatePayment ? <div className="mt-5 p-5"><p className="text-center payment-info-modal-text">To update the credit/debit card information used to hire through Hidrent, please enter it below and click Update Payment Method to save to your account.</p></div>:<div className="mt-5 p-5"><p className="text-center payment-info-modal-text">Please enter your payment information before submitting your Job Request to Hidrent. You will only be charged if your Job Request is booked by Hidrent and after the job has been completed.</p></div>}
      <div className="px-5"><p className="text-uppercase payment-info-modal-text">submit Card info</p></div>
      <div className="px-5"><div className="payment-info-modal-main-div"><Form.Group className="row input-field-modal-first">
        <div className="col-sm-12">

          <CardNumberElement className="form-control" />


        </div>
      </Form.Group>
        <Form.Group className="row input-field-modal-second">
          <div className="col-sm-6">

            <CardExpiryElement className="form-control" />


          </div>
          <div className="col-sm-6">

            <CardCvcElement className="form-control" />


          </div>
        </Form.Group></div></div>
      {cardError !== "" && <p className="px-5 text-right tx-error mt-2 d-flex justify-content-start">
        <strong>{cardError}</strong>
      </p>}
      <div className="my-5 d-flex justify-content-center"><img src={require("../../assets/images/hidrent-small-icon.png")} alt="logo" /></div>

    </Modal.Body>
    <Modal.Footer className="flex-wrap">
      {props.updatePayment ?
        <Button
          variant="success m-2"
          onClick={handleUpdate(stripe, elements)}
        >
          Update Payment Method
        </Button> : <Button
          variant="success m-2"
          onClick={handleSubmit(stripe, elements)}
        >
          Submit Job Request
        </Button>
      }
      <Button variant="light m-2" onClick={() => props.cancelButton()}>
        Back
      </Button>
    </Modal.Footer></>

  )
}

export default PaymentPage;