import React, { Component } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  can,
  isAdmin,
  isMoversSyndicate,
  isBrotherwoodSysterwood,
} from "../../services";
import EditOfficeUser from "../modals/EditOfficeUser";
import { callLogout, callLogout2, doSincerity, sync } from "../../api-routes";
import { logout, saveUser } from "../../actions";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccountModal: false,
    };
  }

  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  goLogout() {
    callLogout().then(() => {
      this.props.logout();
    });
  }

  goLogout2() {
    callLogout2().then(() => {
      this.props.logout();
    });
  }

  sincerity() {
    const _this = this;
    doSincerity().then(function (response) {
      console.log("doSincerity", response);
      sync().then((resp) => {
        console.log("sync", resp);
        if (resp.status === 200 && resp.data.id) {
          _this.props.saveUser(resp.data);
          _this.props.history.push("/users/office-users");
        }
      });
    });
  }

  updateUser() {
    this.setState({ showAccountModal: false });
    const _this = this;
    sync().then((resp) => {
      if (resp.status === 200 && resp.data.id) {
        _this.props.saveUser(resp.data);
        // localStorage.setItem("user", JSON.stringify(resp.data));
      }
    });
  }

  render() {
    const { user } = this.props;
    const { showAccountModal } = this.state;

    return (
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
          <a
            className="navbar-brand brand-logo-mini align-self-center d-lg-none"
            href="!#"
            onClick={(evt) => evt.preventDefault()}
          >
            <img src={require("../../assets/images/ico.png")} alt="logo" />
          </a>
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <i className="mdi mdi-menu"></i>
          </button>
          {/* <ul className="navbar-nav navbar-nav-left header-links">
            <li className="nav-item d-none d-md-flex">
              <a href="!#" onClick={evt =>evt.preventDefault()} className="nav-link"><Trans>Schedule</Trans> <span className="badge badge-primary"><Trans>New</Trans></span>
              </a>
            </li>
            <li className="nav-item active d-none d-xl-flex">
              <a href="!#" onClick={evt =>evt.preventDefault()} className="nav-link">
                <i className="mdi mdi-elevation-rise"></i><Trans>Reports</Trans></a>
            </li>
            <li className="nav-item d-none d-lg-flex">
              <a href="!#" onClick={evt =>evt.preventDefault()} className="nav-link">
                <i className="mdi mdi-bookmark-plus-outline"></i><Trans>Score</Trans></a>
            </li>
          </ul> */}
          <ul className="navbar-nav navbar-nav-right">
            {/* <li className="nav-item  nav-profile border-0">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                  <i className="mdi mdi-file-outline"></i>
                  <span className="count">7</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown preview-list">
                  <Dropdown.Item className="dropdown-item  d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <p className="mb-0 font-weight-medium float-left"><Trans>You have</Trans> 7 <Trans>unread mails</Trans> </p>
                    <span className="badge badge-pill badge-primary">View all</span>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("../../assets/images/faces/face10.jpg")} alt="profile" className="img-sm profile-pic" /> </div>
                    <div className="preview-item-content flex-grow py-2">
                      <p className="preview-subject ellipsis font-weight-medium text-dark"><Trans>Marian Garner</Trans> </p>
                      <p className="font-weight-light small-text"> <Trans>The meeting is cancelled</Trans> </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("../../assets/images/faces/face12.jpg")} alt="profile" className="img-sm profile-pic" /> </div>
                    <div className="preview-item-content flex-grow py-2">
                      <p className="preview-subject ellipsis font-weight-medium text-dark"><Trans>David Grey</Trans> </p>
                      <p className="font-weight-light small-text"> <Trans>The meeting is cancelled</Trans></p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <img src={require("../../assets/images/faces/face1.jpg")} alt="profile" className="img-sm profile-pic" /> </div>
                    <div className="preview-item-content flex-grow py-2">
                      <p className="preview-subject ellipsis font-weight-medium text-dark"><Trans>Travis Jenkins</Trans> </p>
                      <p className="font-weight-light small-text"> <Trans>The meeting is cancelled</Trans> </p>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item  nav-profile border-0 pl-4">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                  <i className="mdi mdi-bell-outline"></i>
                  <span className="count bg-success">4</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown preview-list">
                  <Dropdown.Item className="dropdown-item py-3 d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <p className="mb-0 font-weight-medium float-left"><Trans>You have</Trans> 4 <Trans>new notifications</Trans> </p>
                    <span className="badge badge-pill badge-primary float-right">View all</span>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <i className="mdi mdi-alert m-auto text-primary"></i>
                    </div>
                    <div className="preview-item-content py-2">
                      <h6 className="preview-subject font-weight-normal text-dark mb-1"><Trans>Application Error</Trans></h6>
                      <p className="font-weight-light small-text mb-0"> <Trans>Just now</Trans> </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <i className="mdi mdi-settings m-auto text-primary"></i>
                    </div>
                    <div className="preview-item-content py-2">
                      <h6 className="preview-subject font-weight-normal text-dark mb-1"><Trans>Settings</Trans></h6>
                      <p className="font-weight-light small-text mb-0"> <Trans>Private message</Trans> </p>
                    </div>
                  </Dropdown.Item>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <i className="mdi mdi-airballoon m-auto text-primary"></i>
                    </div>
                    <div className="preview-item-content py-2">
                      <h6 className="preview-subject font-weight-normal text-dark mb-1"><Trans>New user registration</Trans></h6>
                      <p className="font-weight-light small-text mb-0"> 2 <Trans>days ago</Trans> </p>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item d-none d-lg-block" id="color-setting">
              <button type="button" className="nav-link border-0 bg-transparent" onClick={this.toggleRightSidebar} >
                <i className="mdi mdi-tune"></i>
              </button>
            </li> */}
            <li className="nav-item  nav-profile border-0">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  <span className="profile-text">{user && user.fullname}</span>
                  {/* {user && !user.previous_id && (
                    <img
                      className="img-xs rounded-circle"
                      src={require("../../assets/images/faces/face8.jpg")}
                      alt="Profile"
                    />
                  )} */}
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  <Dropdown.Item
                    className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom"
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    {/* <div className="d-flex">
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-bookmark-plus-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                        <i className="mdi mdi-account-outline mr-0"></i>
                      </div>
                      <div className="py-3 px-4 d-flex align-items-center justify-content-center">
                        <i className="mdi mdi-alarm-check mr-0"></i>
                      </div>
                    </div> */}
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center border-0 mt-2"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <Trans>Manage Accounts</Trans>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center border-0"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <Trans>Change Password</Trans>
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center border-0"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <Trans>Check Inbox</Trans>
                  </Dropdown.Item> */}
                  {user && user.previous_id && (
                    <Dropdown.Item
                      className="dropdown-item preview-item d-flex align-items-center border-0"
                      onClick={(evt) => {
                        evt.preventDefault();
                        this.sincerity();
                      }}
                    >
                      <i className="mdi mdi-exit-to-app"></i>{" "}
                      <Trans>Admin</Trans>
                    </Dropdown.Item>
                  )}
                  {!isAdmin(user) &&
                    !isMoversSyndicate(user) &&
                    !isBrotherwoodSysterwood(user) && (
                      <Dropdown.Item
                        className="dropdown-item preview-item d-flex align-items-center border-0"
                        onClick={(evt) => {
                          evt.preventDefault();
                          this.setState({ showAccountModal: true });
                        }}
                      >
                        <Trans>Account</Trans>
                      </Dropdown.Item>
                    )}
                  {can(["view moves"], user) && (
                    <Dropdown.Item
                      className="dropdown-item preview-item d-flex align-items-center border-0"
                      onClick={(evt) => {
                        evt.preventDefault();
                        this.goLogout();
                      }}
                    >
                      <Trans>Sign Out</Trans>
                    </Dropdown.Item>
                  )}
                  {can(["view logout"], user) && (
                    <Dropdown.Item
                      className="dropdown-item preview-item d-flex align-items-center border-0"
                      onClick={(evt) => {
                        evt.preventDefault();
                        this.goLogout2();
                      }}
                    >
                      <Trans>Sign Out</Trans>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
        <Modal
          size="md"
          show={showAccountModal}
          onHide={() => this.setState({ showAccountModal: false })}
          aria-labelledby="example-modal-sizes-title-md"
        >
          <EditOfficeUser
            cancel={() => this.setState({ showAccountModal: false })}
            selectedUser={user}
            isEditing={true}
            profileUpdated={() => this.updateUser()}
          ></EditOfficeUser>
        </Modal>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
  saveUser: (a) => {
    dispatch(saveUser(a));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
