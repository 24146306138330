import axios from "axios";
import { logout } from "../actions";
import { store } from "../config";
const instance = axios.create({withCredentials: true});

instance.interceptors.request.use(async (config) => {
  try {
    config.headers["Content-Type"] = `application/json`;
    if(config.url ==='/register') {
      config.headers["Content-Type"] = `application/x-www-form-urlencoded; charset=UTF-8`;
    }
    config.headers["Accept"] = `application/json`;
    config.credentials = 'same-origin';

    return config;
  } catch (err) {
    return Promise.reject(err);
  }
});

instance.interceptors.response.use(
  (successRes) => {
    if (successRes.status === 200) {
      return { data: successRes.data, status: 200 };
    }
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      store.dispatch(logout());
    }
    if (error.response.status === 400 || error.response.status === 500) {
      return { data: error.response.data, status: error.response.status };
    }

    return Promise.reject(error);
  }
);

export default instance;
