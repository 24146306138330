import { SAVE_USER, SET_IS_ADMIN, LOGOUT } from './types';

export const saveUser = (user) => ({
    type: SAVE_USER,
    user
});

export const setIsAdmin = (val) => ({
    type: SET_IS_ADMIN,
    val
});

export const logout = (user) => ({
    type: LOGOUT,
    user
});