import { SAVE_USER, SET_IS_ADMIN, LOGOUT } from "../actions/types";

const initState = { user: null, isAdmin: false };

const sessionReducer = (state, action) => {
  if (!state) {
    return initState;
  }
  switch (action.type) {
    case SAVE_USER:
      return {
        ...state,
        user: { ...action.user },
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: { ...action.val },
      };
    case LOGOUT:
      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      window.location.reload()
      return initState;
    default:
      return state;
  }
};

export default sessionReducer;
