import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { saveUser } from "../actions";
import { sync } from "../api-routes";
import Spinner from "../app/shared/Spinner";

const Moves = lazy(() => import("./moves/moves"));
const Applicants = lazy(() => import("./moves/applicants"));

const Laborers = lazy(() => import("./users/laborers"));
const Drivers = lazy(() => import("./users/drivers"));
const OfficeUsers = lazy(() => import("./users/office-users"));
const Favourites = lazy(() => import("./users/favourites"));
const CrewLeaders = lazy(() => import("./users/crew-leaders"));
const CrewMembers = lazy(() => import("./users/crew-members"));

const Companies  = lazy(() => import("./companies/companies"));
const CompanyCodes  = lazy(() => import("./companies/company-codes"));

const Messaging  = lazy(() => import("./messaging/messaging"));

const Consortium  = lazy(() => import("./consortium/Consortium"));

const PushNotifications = lazy(() =>
  import("./push-notifications/push-notifications")
);
const LaborersBGCheck = lazy(() => import("./background-check/laborers"));
const LeadersBGCheck = lazy(() => import("./background-check/crew-leaders"));
const CrewMembersBGCheck = lazy(() =>
  import("./background-check/crew-members")
);

const Login = lazy(() => import("./user-pages/Login"));
const Register = lazy(() => import("./user-pages/Register"));
const ChangePassword = lazy(() => import("./user-pages/ChangePassword"));

class AppRoutes extends Component {
  state = {
    user: null,
  };

  async componentDidMount() {
    var user = await localStorage.getItem("user");

    if (user !== null) {
      this.setState({ user: JSON.parse(user) });
      sync().then((resp) => {
        const _this = this;
        if (resp.status === 200 && resp.data.id) {
          _this.props.saveUser(resp.data);
          _this.setState({ user: resp.data });
          // localStorage.setItem("user", JSON.stringify(resp.data));
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const user = this.props.user;
    if (
      !prevProps.user ||
      (prevProps.user && user !== null && prevProps.user.id !== user.id)
    ) {
      sync().then((resp) => {
        const _this = this;
        if (resp.status === 200 && resp.data.id) {
          _this.props.saveUser(resp.data);
          _this.setState({ user: resp.data });
          // localStorage.setItem("user", JSON.stringify(resp.data));
        }
      });
    }
  }

  protectedRoute() {
    return (
      <Switch>
        <Route exact path="/moves/all-moves" component={Moves} />
        <Route exact path="/moves/applicants" component={Applicants} />

        <Route exact path="/users/laborers" component={Laborers} />
        <Route exact path="/users/drivers" component={Drivers} />
        <Route exact path="/users/office-users" component={OfficeUsers} />
        <Route exact path="/users/favourites" component={Favourites} />
        <Route exact path="/users/crew-leaders" component={CrewLeaders} />
        <Route exact path="/users/crew-members" component={CrewMembers} />

        <Route exact path="/companies/all-companies" component={Companies} />
        <Route exact path="/companies/company-codes" component={CompanyCodes} />

        <Route exact path="/messaging" component={Messaging} />

        <Route exact path="/notifications" component={PushNotifications} />
        <Route exact path="/consortiums" component={Consortium} />


        <Route
          exact
          path="/background-check/laborers"
          component={LaborersBGCheck}
        />
        <Route
          exact
          path="/background-check/crew-leaders"
          component={LeadersBGCheck}
        />
        <Route
          exact
          path="/background-check/crew-members"
          component={CrewMembersBGCheck}
        />

        <Redirect to="/moves/all-moves" />
      </Switch>
    );
  }

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        {this.state.user !== null ? (
          this.protectedRoute()
        ) : (
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/change-password" component={ChangePassword} />
            <Redirect to="/login" />
          </Switch>
        )}
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.session.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (a) => {
    dispatch(saveUser(a));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
